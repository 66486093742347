// theme.js
// TODO: Implement toggle switch for light and dark mode

export const materialDarkTheme = {
  body: "#263238",
  text: "#aeaeae",
  expTxtColor: "#000a12",
  highlight: "#4f5b62",
  dark: "#000a12",
  secondaryText: "#aeaeae",
  imageHighlight: "#607d8b",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8eacbb",
  headerColor: "#34515e",
  splashBg: "#4F5B62",
};

/*
export const materialLightTheme = {
  body: "#ffffff",
  text: "#4c2b91",
  expTxtColor: "#000000",
  highlight: "#E9E3F5",
  dark: "#1d0c41",
  secondaryText: "#7d56c2",
  imageHighlight: "#2b1958",
  compImgHighlight: "#E1E2E1",
  jacketColor: "#E1E2E1",
  headerColor: "#E1E2E1",
  splashBg: "#7d56c2",
};
*/

export const chosenTheme = materialDarkTheme;
